document.addEventListener("DOMContentLoaded", () => {
	document.querySelector('.navbar-burger').addEventListener("click", () => {
		document.querySelector(".navbar-menu").classList.toggle("is-active")
		document.querySelector(".navbar-burger").classList.toggle("is-active")
	})
	var imgs = [...document.querySelectorAll("img")].filter(e => e.parentNode.className.includes("dish-item-image"))
	for (var i = 0; i < imgs.length; i++) {
		imgs[i].addEventListener("click", displayImg)
	}
	document.querySelector('.modal-close').addEventListener("click", closeImgModal)
})

const displayImg = e => {
	const src = e.target.src
	document.querySelector("#modal-img").src = src
	document.querySelector("#img-modal").classList.add("is-active")
}

const closeImgModal = () => {
	document.querySelector("#img-modal").classList.remove("is-active")
}
